import React, { useState } from 'react';
import Menu from '../components/Menu';
import '../styles/outils.scss';
import Footer from '../components/Footer';

const Outils = () => {
    const [filter, setFilter] = useState('tout');

    const tools = [
        {
            int: 0,
            name: "Flat UI Colors",
            desc: [
                "Il s'agit d'un site web qui vous propose pleins de palettes de couleurs différentes !",
                "Chaque palette est sur le thème d'un pays avec plusieurs nuances !"
            ],
            site: "https://flatuicolors.com",
            type: 'design',
            animate: 'fade-left'
        },
        {
            int: 1,
            name: "Color Hunt",
            desc: [
                "Il s'agit d'un site web qui vous propose pleins de palettes de couleurs différentes !",
                "Chaque palette ne possède que peu de couleurs mais qui vont forcément ensemble !"
            ],
            site: "https://colorhunt.co/palette/",
            type: 'design',
            animate: 'fade-right'
        },
        {
            int: 2,
            name: "Behance",
            desc: [
                "Ce site permet de trouver de l'inspiration",
                "Un site très utilisé par les professionnels dans le monde du design"
            ],
            site: "https://www.behance.net",
            type: 'design',
            animate: 'fade-left'
        },
        {
            int: 3,
            name: "Material.io",
            desc: [
                "Outil multifonction",
                "Il permet de vous aider dans votre inspiration pour des animations, design, etc...",
                "Trouvez des animations CSS",
                "Trouvez des design originaux..."
            ],
            site: "https://m3.material.io",
            type: 'design',
            animate: 'fade-right'
        },
        {
            int: 4,
            name: "Bulma",
            desc: [
                "Framework CSS/SCSS",
                "Bulma est un framework puissant et léger",
                "Le côté responsive de votre site sera un jeu d'enfant",
                "Des codes couleurs simples et efficaces"
            ],
            site: "https://bulma.io/",
            type: 'framework',
            animate: 'fade-left'
        },
        {
            int: 5,
            name: "Regex101",
            desc: [
                "Outils permettant de créer et tester vos regex",
                "L'interface est très user friendly et permet de vous aider dans la construction de vos regex",
                "Plusieurs types de regex sont disponibles",
            ],
            site: "https://regex101.com",
            type: 'autre',
            animate: 'fade-right'
        },
    ];

    const toolsFiltered = filter === "tout" ? tools : tools.filter((tool) => tool['type'] === filter);

    return (
        <div className='generalContent'>
            <Menu />

            <p className='outils-presentation' data-aos="fade-up">
                Il est important de connaitre pleins d'outils différents qui vont vous permettre de vous aider dans votre quotidien !
                <br />
                Vous trouverez ici une liste d'outils variés pour le monde de l'informatique.
                <br />
                Cette page est mise à jour régulièrement.
            </p>

            <div className='filter-tools-container' data-aos="fade-up">
                    <button className={`button is-info ${filter === 'tout' ? 'is-dark' : ''}`} onClick={() => setFilter('tout')}>Tout</button>
                    <button className={`button is-danger ${filter === 'design' ? 'is-dark' : ''}`} onClick={() => setFilter('design')}>Design</button>
                    <button className={`button is-warning ${filter === 'framework' ? 'is-dark' : ''}`} onClick={() => setFilter('framework')}>Frameworks</button>
                    <button className={`button is-success ${filter === 'outil' ? 'is-dark' : ''}`} onClick={() => setFilter('outil')}>Outils</button>
                    <button className={`button is-primary ${filter === 'autre' ? 'is-dark' : ''}`} onClick={() => setFilter('autre')}>Autres</button>
            </div>

            <div className='outils-container'>
                {
                    toolsFiltered && toolsFiltered.map((tool, index) => (
                        <div className='box' data-aos={tool['animate']} key={index}>
                            <div className='columns'>
                                <div className='column title-tool'>
                                    <a href={ tool['site'] } target='_blank' rel="noreferrer" className='title is-3'>{ tool['name'] }</a>
                                </div>
                                <div className='column'>
                                    <b>{ tool['name'] }</b>
                                    <ul>
                                        {
                                            tool && tool['desc'].map((li, indexli) => (
                                                <li key={indexli}>
                                                    { li }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                    <br />
                                    <a href={ tool['site'] } target='_blank' rel="noreferrer" className='btn-tool'>Visiter le site</a>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>

            <Footer />
        </div>
    );
};

export default Outils;