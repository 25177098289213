import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faBuilding, faCalendar, faLocationDot, faTerminal } from '@fortawesome/free-solid-svg-icons';
import '../styles/cv.scss';

const Cv = () => {
    const expPro = [
        {
            title: "Développeur Full-Stack - UI/UX Design",
            contrat: "Contrat Pro",
            entreprise: "IBM Client Innovation Center",
            lieu: "Lille",
            dates: "2018 - 2019",
            missions: [
                "Développement d'un outil interne permettant de staffer les collaborateurs en fonction de leurs compétences et disponibilités",
                "Refonte complet du Design UI/UX",
                "Développement du FrontEnd en Angular",
                "Développement du BackEnd en NodeJS"
            ]
        },
        {
            title: "TeamLead DevOps et Scrum Master",
            contrat: "CDI",
            entreprise: "IBM Client Innovation Center / Kyndryl",
            lieu: "Lille",
            dates: "2019 - 2022",
            missions: [
                "Gestion d'une équipe de 8 personnes pour une grande banque et assurance française avec pour but de gérer les incidents, maintenir les process déjà existants et en créer des nouveaux",
                "Gestion de la méthode de travail, application de la méthode Agile",
                "Gestion des incidents avec ServiceNow",
                "Développement de Process avec BAW (Business Automation Workflow) et du JavaScript",
                "Création d'un process permettant la création et le déploiement d'une machine virtuelle via un formulaire web",
                "Développement d'outils en Python pour automatiser certaines tâches"
            ]
        },
        {
            title: "Product Owner Bases de données",
            contrat: "CDI",
            entreprise: "Synapsys Groupe",
            lieu: "Lille",
            dates: "2022 - 2024",
            missions: [
                "Pour une grande entreprise du Retail, je devais gérer tout le parc des bases de données (MongoDB et Oracle)",
                "Gestion des incidents, prévoir les trafics importants lors des opérations commerciales",
                "Communication avec les Products Leader dans le but d'optimiser et améliorer leurs requêtes",
                "Gestion des bases de données Oracle et MongoDB",
                "Analyse et optimisation des bases de données",
                "Discussions avec les différents Product Leader afin d'améliorer leurs requêtes et utilisation de leurs bases",
                "Création d'un outil en React et Python permettant de réaliser des audits de performance sur les bases, gestion des droits, visualisation des coûts, etc..."
            ]
        },
        {
            title: "Développeur FullStack",
            contrat: "CDI",
            entreprise: "Extia",
            lieu: "Lille",
            dates: "2024 - 2024",
            missions: [
                "Formation PowerBi",
                "Formation Docker",
                "Mécénat de compétences pour une association en tant que Product Owner sur un projet de refonte de leur site web"
            ]
        },
        {
            title: "Vacataire Professeur Université",
            contrat: "CDI",
            entreprise: "Université d'Artois",
            lieu: "Béthune",
            dates: "2022 - en cours",
            missions: [
                "Depuis 2022, je donne des cours à des premières années en BUT Réseaux et Télécomms, les cours sont orientés sur le développement web (front et back), les bases de données et la sensibilisation à la cyber sécurité",
                "Initiation au HTML et CSS",
                "Apprentissage des bases du JavaScript",
                "Initiation sur Python avec Flask",
                "Initiation aux bases de données MySQL",
                "Initiation à la cybersécurité",
                "Réalisation de 4 TPs",
                "DS + CTP"
            ]
        }
    ];

    const etudes = [
        {
            diplome: "BAC STI2D - option SIN",
            ecole: "Lycée André Malraux",
            ville: "Béthune",
            annees: "2015 - 2016",
            description: [
                "Études de développement et électronique"
            ]
        },
        {
            diplome: "DUT Réseaux et Télécommunications",
            ecole: "Université d'Artois",
            ville: "Béthune",
            annees: "2016 - 2018",
            description: [
                "Études de développement web et logiciel",
                "Études de réseaux et télécommunications"
            ]
        },
        {
            diplome: "Licence Pro CréaWeb",
            ecole: "Université d'Artois",
            ville: "Lens",
            annees: "2018 - 2019",
            description: [
                "Études de développement web (front)",
                "Études des bases de données",
                "Études du design (UI/UX)"
            ]
        }
    ];

    return (
        <div className='cv-container'>
            <div className='info'>
                <h1 className='title is-1' data-aos="fade-left">Maxime Lefebvre</h1>
                <h3 className='title is-3' data-aos="fade-right">Couteau suisse en informatique</h3>
            </div>


            <div className='exp-section'>
                <h1 className='title is-3 title-section' data-aos="fade-left">Mes Expériences Professionnelles</h1>
                {
                    expPro && (
                        expPro.map((exp, index) => (
                            <div className='box' key={index} data-aos="fade-up">
                                <h1 className='title is-5'>{ exp['title'] }</h1>
                                <div className='columns'>
                                    <div className='column is-one-third is-information'>
                                        <p>{ exp['contrat'] } <FontAwesomeIcon icon={faBriefcase} /></p>
                                        <p>{ exp['entreprise'] } <FontAwesomeIcon icon={faBuilding} /></p>
                                        <p>{ exp['lieu'] } <FontAwesomeIcon icon={faLocationDot} /></p>
                                        <p>{ exp['dates'] } <FontAwesomeIcon icon={faCalendar} /></p>
                                    </div>
                                    <div className='column is-description'>
                                        <ul>
                                            {
                                                exp['missions'].map((mission, index2) => (
                                                    <li key={index2}>
                                                        <FontAwesomeIcon icon={faTerminal} />&nbsp;
                                                        { mission }
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))
                    )
                }
            </div>

            <div className='etudes-section'>
                <h1 className='title is-3 title-section' data-aos="fade-left">Mes Études</h1>
                {
                    etudes && (
                        etudes.map((etude, index) => (
                            <div className='box' key={index} data-aos="fade-up">
                                <h1 className='title is-5'>{ etude['diplome'] }</h1>
                                <div className='columns'>
                                    <div className='column is-one-third is-information'>
                                        <p>{ etude['ecole'] } <FontAwesomeIcon icon={faBuilding} /></p>
                                        <p>{ etude['ville'] } <FontAwesomeIcon icon={faLocationDot} /></p>
                                        <p>{ etude['annees'] } <FontAwesomeIcon icon={faCalendar} /></p>
                                    </div>
                                    <div className='column is-description'>
                                        <ul>
                                            {
                                                etude['description'].map((desc, index2) => (
                                                    <li key={index2}>
                                                        <FontAwesomeIcon icon={faTerminal} />&nbsp;
                                                        { desc }
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))
                    )
                }
            </div>

            <div className='comp-section'></div>
        </div>
    );
};

export default Cv;