import React from 'react';
import Menu from '../components/Menu';
import Max from '../assets/img/moi.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faSchool, faToolbox } from '@fortawesome/free-solid-svg-icons';
import Footer from '../components/Footer';
import '../styles/home.scss';
import Contact from '../components/Contact';

const Home = () => {
    return (
        <div className='generalContent'>
            <Menu />

            <div className='presentation-container' data-aos="fade-up">
                <div className="columns is-vcentered">
                    <div className="column isRCentered">
                        <img src={Max} className='profile-photo' alt="Maxime Lefebvre" />
                    </div>
                    <div className="column presentation">
                        Bonjour ! <br />
                        Je suis Maxime Lefebvre, âgé de 26 ans je suis un couteau suisse dans l'informatique !
                    </div>
                </div>
            </div>

            <div className='info-container' data-aos="fade-up">
                <div className='columns is-multiline is-centered'>
                    <div className='column is-one-third'>
                        <div className='box'>
                            <p className='title is-5'>
                                <FontAwesomeIcon icon={faBook} /> Documentations
                            </p>
                            <p className='description'>
                                Je mets à disposition <b>gratuitement</b>, un site vous permettants d'accéder à différentes ressources !
                            </p>
                        </div>
                    </div>
                    <div className='column is-one-third'>
                        <div className='box'>
                            <p className='title is-5'>
                                <FontAwesomeIcon icon={faSchool} /> Apprentissage
                            </p>
                            <p className='description'>
                                Depuis le portail de documentations vous avez accès à des cours sur du développement web et également quelques tutos sur des outils !
                            </p>
                        </div>
                    </div>
                    <div className='column is-one-third'>
                        <div className='box'>
                            <p className='title is-5'>
                                <FontAwesomeIcon icon={faToolbox} /> Outils
                            </p>
                            <p className='description'>
                                Je vous propose de découvrir et partager avec vous les outils que j'utilise régulièrement sur mes projets personnels.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Contact />

            <Footer />
        </div>
    );
};

export default Home;