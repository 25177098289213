import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('Choisir un sujet');
    const [message, setMessage] = useState('');
    const [msgErr, setMsgErr] = useState('');
    const [errType, setErrType] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Init var
        setSuccess(false);
        setError(false);
        setErrType('');
        setMsgErr('');

        // init form
        const currentForm = {
            name: name,
            email: email,
            subject: subject,
            message: message
        };

        // check content form
        if (name.length < 5) {
            setError(true);
            setErrType('name');
            setMsgErr('Veuillez indiquer votre nom et prénom !');
        } else if (subject === 'Choisir un sujet') {
            setError(true);
            setErrType('sujet');
            setMsgErr('Veuillez choisir un sujet !');
        } else if (message.length < 30) {
            setError(true);
            setErrType('message');
            setMsgErr('Le message doit dépasser 30 caractères !');
        } else {
            emailjs.send(
                        process.env.REACT_APP_EMAILJS_SERVICE_ID,
                        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                        currentForm,
                        process.env.REACT_APP_EMAILJS_USER_ID
            ).then((result) => {
                setSuccess(true);
                setName('');
                setEmail('');
                setSubject('Choisir un sujet');
                setMessage('');
                setErrType('');
                setError(false);
                setMsgErr('');
            }, (error) => {
                setError(true);
                setMessage('Une erreur est survenue durant l\'envoi du message...');
            });
        }
    };

    return (
        <div className='contact-container' data-aos="fade-right">
            <p className='title is-3'>
                <span className='icon-title'><FontAwesomeIcon icon={faTerminal} /></span>
                Besoin de me contacter ?
            </p>

            {
                error && (
                    <div className='notification is-danger is-light'>
                        <button onClick={() => setError(false)} className='delete'></button>
                        { msgErr && <p>{ msgErr }</p> }
                    </div>
                )
            }

            <form className='form-container' onSubmit={handleSubmit}>
                <div className='field'>
                    <label className='label'>Nom et Prénom</label>
                    <div className='control'>
                        <input className={errType === 'name' ? 'input is-danger' : 'input'} type='text' placeholder='John Doe' onChange={(e) => setName(e.target.value)} />
                    </div>
                </div>

                <div className='field'>
                    <label className='label'>Adresse mail</label>
                    <div className='control'>
                        <input className='input' type='email' placeholder='johndoe@exemple.com' onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>

                <div className='field'>
                    <label className='label'>Sujet</label>
                    <div className='control'>
                        <div className={errType === 'sujet' ? 'select is-danger' : 'select'}>
                            <select onChange={(e) => setSubject(e.target.value)}>
                                <option>Choisir un sujet</option>
                                <option>Demande informations</option>
                                <option>Site web</option>
                                <option>Autres</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className='field'>
                    <label className='label'>Message</label>
                    <div className='control'>
                        <textarea className={errType === 'message' ? 'textarea is-danger' : 'textarea'} placeholder='Votre message...' onChange={(e) => setMessage(e.target.value)}></textarea>
                    </div>
                </div>

                {
                    success && (
                        <div className='notification is-success is-light'>
                            <button onClick={() => setSuccess(false)} className='delete'></button>
                            Votre message a été envoyé avec succès !
                        </div>
                    )
                }

                <div className='btn-box'>
                    <button className='button is-info'>Envoyer le message</button>
                </div>
            </form>
        </div>
    );
};

export default Contact;