import React from 'react';

const Footer = () => {
    return (
        <div className='footer-container'>
            <b>&copy; Copyright Maxime Lefebvre 2024 - 2025</b>
        </div>
    );
};

export default Footer;