import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'aos/dist/aos.css';
import AOS from 'aos';
import './styles/global.scss';
import Home from './pages/Home';
import Outils from './pages/Outils';
import Cv from './pages/Cv';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/outils' element={<Outils />} />
        <Route path='/cv' element={<Cv />} />
        <Route path='*' element={<h1>404 Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;