import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import '../styles/menu.scss';

const Menu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className={`menu-container ${isMenuOpen ? 'menu-open' : ''}`}>
            <button className="menu-icon" onClick={toggleMenu}>
                {isMenuOpen ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faBars} />}
            </button>

            <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
                <a href="/">Accueil</a>
                <a href="/cv" target="_blank" rel="noreferrer">Mon CV</a>
                <a href="/outils">Outils</a>
                <a href="https://docs.maximelefebvre.fr" target="_blank" rel="noreferrer">Documentations</a>
            </div>
        </div>
    );
};

export default Menu;